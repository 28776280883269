<template>
  <div class="chart">
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import BaseChart from "./BaseChart.vue";
export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      chartType: "bar",
      chartOptions: {
        indexAxis: "y",
        responsive: true,
        scales: {
          x: {
            display: false,
            grid: {
              borderColor: "#FFFFFF",
              color: "#FFFFFF",
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
          },
          y: {
            display: true,
            stacked: true,
            ticks: {
              color: "#FCFCFC",
              crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 14,
              }
            },
            grid: {
              borderColor: "#FFFFFF",
              drawBorder: false,
              drawOnChartArea: false,
              color: "#FFFFFF",
              drawTicks: false,
              // lineWidth: 4,
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: function (context) {
              if (context.dataset.name == "real") {
                return true;
              }
              return false;
            },
            anchor: function (context) {
              const index = context.dataIndex;
              const value = context.dataset.data[index];
              if (value > 0) {
                return "end";
              }
              return "start";
            },
            color: "#ffffff",
            align: function (context) {
              const index = context.dataIndex;
              const value = context.dataset.data[index];
              if (value > 0) {
                return "end";
              }
              return "start";
            },
            formatter: function (value) {
              return Math.abs(value);
            },
          },
        },
      },
      plugins: [],
    };
  },
};
</script>

<style></style>
