<template>
  <div class="chart">
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
    />
  </div>
</template>

<script>
import BaseChart from "./BaseChart.vue";
export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      chartType: "bar",
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            display: true,
            grid: {
              borderColor: "transparent",
              color: "transparent",
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              color: "#fff",
              // crossAlign: 'far',
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 14,
              },
            },
          },
          y: {
            display: true,
            grid: {
              borderColor: "#FFFFFF",
              drawBorder: false,
              color: "#112138",
              drawTicks: false,
              lineWidth: 3,
            },
            ticks: {
              color: "#fff",
              crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 14,
              },
              stepSize: 5,
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: function (context) {
              if (context.chart.data.hideLegend) {
                return false;
              }
              return true;
            },
            borderRadius: 20,
            position: "bottom",
            align: "start",
            color: "#fff",
            padding: 10,
            labels: {
              // padding: 10,
              pointStyle: "circle",
              usePointStyle: true,
              color: "#fff",
              font: {
                size: 14,
              },
              filter: (legendItem) => {
                // First, retrieve the data corresponding to that label
                // const label = legendItem.text
                // const labelIndex = _.findIndex(data.labels, (labelName) => labelName === label) // I'm using lodash here
                // const qtd = data.datasets[0].data[labelIndex]
                
                // // Second, mutate the legendItem to include the new text
                // legendItem.text = 'abc'

                if(legendItem.datasetIndex == 0) {
                  legendItem.fillStyle = '#027A80'; 
                } else {
                  legendItem.fillStyle = '#C5FCFA'; 
                }
                
                
                // Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                return true
              }
            },
          },
          tooltip: {
            enabled: true,
          },
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
};
</script>
