<template>
  <div class="chart">
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
    />
  </div>
</template>

<script>
import BaseChart from "./BaseChart.vue";
export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      chartType: "line",
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 20,
            // top: 20,
            bottom: 20,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              borderColor: "transparent",
              color: "transparent",
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              maxRotation: 0,
              color: "#fff",
            },
          },
          y: {
            display: true,
            stacked: false,
            grid: {
              borderColor: "#112138",
              drawBorder: false,
              color: "#112138",
              drawTicks: false,
              offset: true,
            },
            ticks: {
              color: "#fff",
              stepSize: 200,
              padding: 10,
              callback: function(value) {
                return value;
              }
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "center",

            labels: {
              padding: 30,
              pointStyle: "circle",
              usePointStyle: true,
              color: "#fff",
              font: {
                size: 14,
              },
              filter: (legendItem) => {

                if(legendItem.datasetIndex == 0) {
                  legendItem.fillStyle = '#127A80'; 
                } else {
                  legendItem.fillStyle = '#E0F2BF'; 
                }
                
                return true
              }
            },
            reverse: true,
          },
          tooltip: {
            enabled: true,
          },
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
};
</script>
