<template>
  <div :class="chartType">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: {
    chartId: String,
    chartType: String,
    chartData: Object,
    chartOptions: Object,
    plugins: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    chartConstructor(chartType, chartData, chartOptions, plugins) {
      const chartConfigure = {
        type: chartType,
        data: chartData,
        options: chartOptions,
      };
      if (plugins?.length) {
        chartConfigure.plugins = plugins;
      }
      const chartElement = document.getElementById(this.chartId);
      this.chart = new Chart(chartElement, chartConfigure);
    },
    updateChart(chartData) {
      this.chart.data = chartData;
      this.chart.update();
    },
  },

  mounted() {
    let { chartType, chartData, chartOptions, plugins } = this;
    this.chartConstructor(chartType, chartData, chartOptions, plugins);
  },
};
</script>

<style>
canvas {
  height: 250px;
}
</style>
