<template>
  <div id="globus" style="width: 100%; height: 100%"></div>
</template>
<script>
import BlackGlobe from "../plugins/BlackGlobe";
import { layer, terrain, LonLat, Entity } from "@openglobus/og";
const img = require("../assets/pages/dashboard/map/map-pin.svg");
// const font = require("../assets/fonts/Roboto-Regular.json");
// const fontImg = require("../assets/fonts/Roboto-Regular.ttf.png");

export default {
  name: "openglobus",
  props: {
    sites: {
      type: Array,
      default: function () {
        return [];
      },
    },
    searchByPoi: {
      type: Function,
      // Unlike object or array default, this is not a factory function - this is a function to serve as a default value
      default() {
        return "Default function";
      },
    },
  },
  data() {
    return {
      osm: null,
      globus: null,
      pointLayer: null,
    };
  },
  mounted() {
    const { XYZ } = layer;
    const { GlobusTerrain } = terrain;

    this.osm = new XYZ("OpenStreetMap", {
      isBaseLayer: true,
      url: "//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      visibility: true,
      crossOrigin: true,
    });

    this.globus = new BlackGlobe({
      target: "globus", // a HTMLDivElement which its id is `globus`
      name: "Earth",
      terrain: new GlobusTerrain(),
      layers: [this.osm],
      autoActivated: true,
      viewExtent: [5.56707, 45.15679, 5.88834, 45.2226],
    });

    this.globus.renderer.fontAtlas.loadFont("Roboto-Regular", ".", "Roboto-Regular.json");

    if (this.sites?.length) {
      this.globus.planet.flyLonLat(new LonLat(2, 48, 20108312));
      this.pointLayer = new layer.Vector("Markers", {
        clampToGround: true,
      }).addTo(this.globus.planet);
      // .add(
      //   new Entity({
      //     lonlat: [5.73, 45.183],
      //     label: {
      //       text: "Hello, Globus!",
      //       outline: 0.14,
      //       outlineColor: "grey",
      //       face: "Roboto-Regular",
      //       size: 32,
      //       color: "white",
      //       offset: [10, -2],
      //     },
      //     billboard: {
      //       src: img,
      //       width: 64,
      //       height: 64,
      //       offset: [0, 32],
      //     },
      //   })
      // );
      const {
        position: { lat, lng },
      } = this.sites[0];
      this.globus.planet.flyLonLat(new LonLat(lng, lat, 20000000));

      this.pointLayer.events.on("ldown", (e) => {
        e.renderer.controls.mouseNavigation.deactivate();
        this.searchByPoi(e.pickingObject.properties.dataId);
        const { lon, lat } = e.pickingObject._lonlat;
        this.globus.planet.flyLonLat(new LonLat(lon, lat, 20000000));
      });
      this.pointLayer.events.on("lup", (e) => {
        e.renderer.controls.mouseNavigation.activate();
      });

      this.sites.map((val) => {
        const {
          position: { lat, lng },
        } = val;
        this.pointLayer.add(
          new Entity({
            properties: {
              dataId: val.id,
            },
            name: val.name,
            lonlat: new LonLat(lng, lat, 20000000),
            label: {
              text: val.name,
              outline: 0.1,
              outlineColor: "rgba(255,255,255)",
              size: 20,
              color: "black",
              offset: [0, 20],
              face: "Roboto-Regular",
              style: "normal",
              align: "center",
            },
            billboard: {
              src: img,
              width: 40,
              height: 40,
              offset: [0, 0],
            },
          })
        );
      });
    }
  },
  methods: {
    flyTo(lng, lat) {
      this.globus.planet.flyLonLat(new LonLat(lng, lat, 20000000));
    },
  },
};
</script>
