export const mockGroups = [
    {
        id: 1,
        name: "Frozen Yogurt",
        allowAdd: true,
        allowEdit: false,
        allowDelete: true,
        createdDate: "Jun 22, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": true,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 2,
        name: "Ice cream sandwich",
        allowAdd: true,
        allowEdit: false,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": false,
            "schedule": true,
            "report": false,
            "alarms": false,
            "user": true
        },
    },
    {
        id: 3,
        name: "Eclair",
        allowAdd: true,
        allowEdit: true,
        allowDelete: false,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 4,
        name: "Cupcake",
        allowAdd: false,
        allowEdit: false,
        allowDelete: false,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": false,
            "schedule": false,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 5,
        name: "Gingerbread",
        allowAdd: false,
        allowEdit: true,
        allowDelete: true,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 6,
        name: "Jelly bean",
        allowAdd: false,
        allowEdit: false,
        allowDelete: true,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 7,
        name: "Lollipop",
        allowAdd: true,
        allowEdit: true,
        allowDelete: false,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 8,
        name: "Honeycomb",
        allowAdd: true,
        allowEdit: false,
        allowDelete: true,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 9,
        name: "Donut",
        allowAdd: true,
        allowEdit: false,
        allowDelete: false,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
    {
        id: 10,
        name: "KitKat",
        allowAdd: false,
        allowEdit: false,
        allowDelete: true,
        createdDate: "Jun 21, 2021",
        accessible_menus: {
            "dashboard": true,
            "der_managerment": true,
            "schedule": true,
            "report": false,
            "alarms": true,
            "user": true
        },
    },
];

export const mockUsers = [
    {
        "id": "73",
        "fullName": "Willyt Edmondson",
        "email": "wedmondson0@reuters.com",
        "phoneNum": "878-948-7202",
        "group": "User",
        "registerDate": "10/24/2020",
        "userName": "wedmondson0",
        "status": "active"
    },
    {
        "id": "73813",
        "fullName": "Berton Slinger",
        "email": "bslinger1@linkedin.com",
        "phoneNum": "411-329-3693",
        "group": "Operator",
        "registerDate": "10/26/2020",
        "userName": "bslinger1",
        "status": "inactive"
    },
    {
        "id": "4",
        "fullName": "Zonda Janny",
        "email": "zjanny2@ifeng.com",
        "phoneNum": "173-931-9955",
        "group": "Superadmin",
        "registerDate": "3/19/2021",
        "userName": "zjanny2",
        "status": "locked"
    },
    {
        "id": "1",
        "fullName": "Zachery Dawkes",
        "email": "zdawkes3@dailymail.co.uk",
        "phoneNum": "218-550-0395",
        "group": "Operator",
        "registerDate": "7/17/2020",
        "userName": "zdawkes3",
        "status": "locked"
    },
    {
        "id": "599",
        "fullName": "Livia Waddicor",
        "email": "lwaddicor4@privacy.gov.au",
        "phoneNum": "934-370-7154",
        "group": "Superadmin",
        "registerDate": "4/17/2021",
        "userName": "lwaddicor4",
        "status": "locked"
    },
    {
        "id": "7229",
        "fullName": "Rhea Debling",
        "email": "rdebling5@cbslocal.com",
        "phoneNum": "489-821-6863",
        "group": "Superadmin",
        "registerDate": "5/21/2021",
        "userName": "rdebling5",
        "status": "inactive"
    },
    {
        "id": "8512",
        "fullName": "Ally Sollars",
        "email": "asollars6@nytimes.com",
        "phoneNum": "858-799-8186",
        "group": "Operator",
        "registerDate": "6/18/2021",
        "userName": "asollars6",
        "status": "locked"
    },
    {
        "id": "0528",
        "fullName": "Birdie Sterricker",
        "email": "bsterricker7@state.tx.us",
        "phoneNum": "228-561-3492",
        "group": "Operator",
        "registerDate": "6/4/2021",
        "userName": "bsterricker7",
        "status": "locked"
    },
    {
        "id": "9",
        "fullName": "Marion Pipkin",
        "email": "mpipkin8@soundcloud.com",
        "phoneNum": "719-855-4358",
        "group": "Operator",
        "registerDate": "4/15/2021",
        "userName": "mpipkin8",
        "status": "inactive"
    },
    {
        "id": "6342",
        "fullName": "Sylvan Booley",
        "email": "sbooley9@issuu.com",
        "phoneNum": "683-898-6299",
        "group": "Operator",
        "registerDate": "8/6/2020",
        "userName": "sbooley9",
        "status": "active"
    },
    {
        "id": "6296",
        "fullName": "Genia Larvin",
        "email": "glarvina@goo.gl",
        "phoneNum": "918-971-4040",
        "group": "User",
        "registerDate": "1/16/2021",
        "userName": "glarvina",
        "status": "active"
    },
    {
        "id": "06792",
        "fullName": "Miriam McIlwraith",
        "email": "mmcilwraithb@gov.uk",
        "phoneNum": "346-999-5653",
        "group": "User",
        "registerDate": "11/27/2020",
        "userName": "mmcilwraithb",
        "status": "inactive"
    },
    {
        "id": "30",
        "fullName": "Bebe Forsey",
        "email": "bforseyc@skyrock.com",
        "phoneNum": "921-776-7200",
        "group": "User",
        "registerDate": "4/16/2021",
        "userName": "bforseyc",
        "status": "inactive"
    },
    {
        "id": "71778",
        "fullName": "Linea Askell",
        "email": "laskelld@theglobeandmail.com",
        "phoneNum": "459-688-0367",
        "group": "User",
        "registerDate": "4/7/2021",
        "userName": "laskelld",
        "status": "active"
    },
    {
        "id": "01",
        "fullName": "Fidela Fawlo",
        "email": "ffawloe@tripod.com",
        "phoneNum": "424-787-7505",
        "group": "Operator",
        "registerDate": "8/29/2020",
        "userName": "ffawloe",
        "status": "active"
    },
    {
        "id": "9938",
        "fullName": "Efren Willimont",
        "email": "ewillimontf@mediafire.com",
        "phoneNum": "803-260-8841",
        "group": "Superadmin",
        "registerDate": "12/16/2020",
        "userName": "ewillimontf",
        "status": "active"
    },
    {
        "id": "3",
        "fullName": "Fernanda Entissle",
        "email": "fentissleg@altervista.org",
        "phoneNum": "507-283-5605",
        "group": "User",
        "registerDate": "10/7/2020",
        "userName": "fentissleg",
        "status": "active"
    },
    {
        "id": "79584",
        "fullName": "Iago Itzkovich",
        "email": "iitzkovichh@netlog.com",
        "phoneNum": "722-451-8514",
        "group": "Operator",
        "registerDate": "11/25/2020",
        "userName": "iitzkovichh",
        "status": "locked"
    },
    {
        "id": "49179",
        "fullName": "Garland De Winton",
        "email": "gdei@omniture.com",
        "phoneNum": "175-447-4654",
        "group": "Operator",
        "registerDate": "6/24/2021",
        "userName": "gdei",
        "status": "locked"
    },
    {
        "id": "2078",
        "fullName": "Jeane Drain",
        "email": "jdrainj@loc.gov",
        "phoneNum": "544-776-3919",
        "group": "Superadmin",
        "registerDate": "2/28/2021",
        "userName": "jdrainj",
        "status": "active"
    }
]

export const mockAlarms = [
    {
        "alarmId": "19691",
        "siteId": "956",
        "deviceId": "641",
        "severity": "minor",
        "errorCode": "895001029-1",
        "description": "Proactive interactive Graphical User Interface",
        "occured": "81-691-1117",
        "updatedOn": "07-Aug-2020",
        "alarmStatus": "resolved",
        "acknowledgedOn": "02-Aug-2020"
    },
    {
        "alarmId": "5",
        "siteId": "10639",
        "deviceId": "4",
        "severity": "critical",
        "errorCode": "360809449-0",
        "description": "Right-sized client-driven synergy",
        "occured": "93-835-8177",
        "updatedOn": "19-Aug-2020",
        "alarmStatus": "resolved",
        "acknowledgedOn": "09-Dec-2020"
    },
    {
        "alarmId": "7662",
        "siteId": "876",
        "deviceId": "4",
        "severity": "minor",
        "errorCode": "960443256-7",
        "description": "Optional eco-centric infrastructure",
        "occured": "01-920-9636",
        "updatedOn": "23-Nov-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "14-Jun-2021"
    },
    {
        "alarmId": "1",
        "siteId": "710",
        "deviceId": "4098",
        "severity": "critical",
        "errorCode": "109959132-5",
        "description": "Front-line asynchronous neural-net",
        "occured": "87-423-1209",
        "updatedOn": "09-Apr-2021",
        "alarmStatus": "resolved",
        "acknowledgedOn": "02-Sep-2020"
    },
    {
        "alarmId": "178",
        "siteId": "64",
        "deviceId": "488",
        "severity": "critical",
        "errorCode": "894355043-X",
        "description": "Up-sized global software",
        "occured": "18-904-1196",
        "updatedOn": "24-May-2021",
        "alarmStatus": "active",
        "acknowledgedOn": "05-Jun-2021"
    },
    {
        "alarmId": "01220",
        "siteId": "226",
        "deviceId": "60688",
        "severity": "minor",
        "errorCode": "160997227-9",
        "description": "Enterprise-wide uniform synergy",
        "occured": "89-454-8366",
        "updatedOn": "04-Feb-2021",
        "alarmStatus": "active",
        "acknowledgedOn": "17-May-2021"
    },
    {
        "alarmId": "623",
        "siteId": "62351",
        "deviceId": "84022",
        "severity": "minor",
        "errorCode": "102728337-3",
        "description": "Ergonomic secondary extranet",
        "occured": "13-060-8500",
        "updatedOn": "09-May-2021",
        "alarmStatus": "active",
        "acknowledgedOn": "14-Aug-2020"
    },
    {
        "alarmId": "72",
        "siteId": "6",
        "deviceId": "73",
        "severity": "critical",
        "errorCode": "341072106-1",
        "description": "Organized well-modulated solution",
        "occured": "09-415-1458",
        "updatedOn": "08-Oct-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "28-Jun-2021"
    },
    {
        "alarmId": "197",
        "siteId": "582",
        "deviceId": "61",
        "severity": "major",
        "errorCode": "633399858-3",
        "description": "Triple-buffered context-sensitive alliance",
        "occured": "96-582-7387",
        "updatedOn": "25-Jun-2021",
        "alarmStatus": "resolved",
        "acknowledgedOn": "28-May-2021"
    },
    {
        "alarmId": "93618",
        "siteId": "91",
        "deviceId": "584",
        "severity": "major",
        "errorCode": "642969533-6",
        "description": "Polarised uniform functionalities",
        "occured": "22-108-5614",
        "updatedOn": "28-Sep-2020",
        "alarmStatus": "active",
        "acknowledgedOn": "15-Aug-2020"
    },
    {
        "alarmId": "93",
        "siteId": "35801",
        "deviceId": "4",
        "severity": "major",
        "errorCode": "737999795-5",
        "description": "Cross-group static moderator",
        "occured": "87-504-0453",
        "updatedOn": "01-Sep-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "19-Feb-2021"
    },
    {
        "alarmId": "3963",
        "siteId": "39620",
        "deviceId": "8500",
        "severity": "minor",
        "errorCode": "071977504-3",
        "description": "Vision-oriented client-driven secured line",
        "occured": "64-829-0755",
        "updatedOn": "04-Mar-2021",
        "alarmStatus": "active",
        "acknowledgedOn": "12-Oct-2020"
    },
    {
        "alarmId": "75764",
        "siteId": "0",
        "deviceId": "42",
        "severity": "critical",
        "errorCode": "868386447-2",
        "description": "Phased multi-tasking emulation",
        "occured": "29-888-4128",
        "updatedOn": "24-Jan-2021",
        "alarmStatus": "resolved",
        "acknowledgedOn": "09-Dec-2020"
    },
    {
        "alarmId": "5788",
        "siteId": "94",
        "deviceId": "17162",
        "severity": "major",
        "errorCode": "475073173-0",
        "description": "Customizable disintermediate product",
        "occured": "92-785-2104",
        "updatedOn": "28-Dec-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "26-Aug-2020"
    },
    {
        "alarmId": "39484",
        "siteId": "969",
        "deviceId": "23576",
        "severity": "major",
        "errorCode": "747946274-3",
        "description": "Profound exuding core",
        "occured": "62-336-0397",
        "updatedOn": "27-Jul-2020",
        "alarmStatus": "active",
        "acknowledgedOn": "21-Apr-2021"
    },
    {
        "alarmId": "8",
        "siteId": "468",
        "deviceId": "524",
        "severity": "major",
        "errorCode": "836419714-2",
        "description": "Upgradable neutral application",
        "occured": "30-005-0913",
        "updatedOn": "19-Oct-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "14-Jul-2021"
    },
    {
        "alarmId": "6",
        "siteId": "21972",
        "deviceId": "4",
        "severity": "major",
        "errorCode": "276933393-3",
        "description": "User-friendly stable functionalities",
        "occured": "18-297-4479",
        "updatedOn": "03-Aug-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "11-May-2021"
    },
    {
        "alarmId": "7",
        "siteId": "9",
        "deviceId": "755",
        "severity": "minor",
        "errorCode": "548054746-1",
        "description": "Fundamental disintermediate ability",
        "occured": "44-439-7285",
        "updatedOn": "14-Sep-2020",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "07-Nov-2020"
    },
    {
        "alarmId": "61407",
        "siteId": "2",
        "deviceId": "2488",
        "severity": "critical",
        "errorCode": "217409174-7",
        "description": "Integrated homogeneous functionalities",
        "occured": "24-677-2870",
        "updatedOn": "12-Jan-2021",
        "alarmStatus": "active",
        "acknowledgedOn": "01-May-2021"
    },
    {
        "alarmId": "88245",
        "siteId": "9077",
        "deviceId": "20470",
        "severity": "minor",
        "errorCode": "848247447-2",
        "description": "Object-based client-driven system engine",
        "occured": "07-860-8444",
        "updatedOn": "11-May-2021",
        "alarmStatus": "acknowledge",
        "acknowledgedOn": "28-Mar-2021"
    }
]

export const mockReports = [
    {
        "createdReport": "Synergized bi-directional migration",
        "reportType": "Faults",
        "deviceId": "073546129-5",
        "createdDate": "06/12/2021"
    },
    {
        "createdReport": "Assimilated non-volatile middleware",
        "reportType": "Faults",
        "deviceId": "239766455-0",
        "createdDate": "04/25/2021"
    },
    {
        "createdReport": "Networked context-sensitive frame",
        "reportType": "OCPP logs",
        "deviceId": "171661316-7",
        "createdDate": "10/12/2020"
    },
    {
        "createdReport": "Robust dedicated framework",
        "reportType": "Session Reports",
        "deviceId": "621081262-7",
        "createdDate": "02/14/2021"
    },
    {
        "createdReport": "Re-contextualized dedicated success",
        "reportType": "Faults",
        "deviceId": "432124429-3",
        "createdDate": "03/26/2021"
    },
    {
        "createdReport": "Profound actuating flexibility",
        "reportType": "OCPP logs",
        "deviceId": "521213009-3",
        "createdDate": "10/02/2020"
    },
    {
        "createdReport": "Total composite core",
        "reportType": "OCPP logs",
        "deviceId": "064527263-9",
        "createdDate": "12/21/2020"
    },
    {
        "createdReport": "Optional 24/7 task-force",
        "reportType": "OCPP logs",
        "deviceId": "702518528-0",
        "createdDate": "07/20/2020"
    },
    {
        "createdReport": "Switchable well-modulated attitude",
        "reportType": "Faults",
        "deviceId": "347731673-8",
        "createdDate": "05/15/2021"
    },
    {
        "createdReport": "Reduced value-added structure",
        "reportType": "Session Reports",
        "deviceId": "267850756-4",
        "createdDate": "05/04/2021"
    },
    {
        "createdReport": "Integrated motivating process improvement",
        "reportType": "Session Reports",
        "deviceId": "225937332-1",
        "createdDate": "03/17/2021"
    },
    {
        "createdReport": "Extended bi-directional hub",
        "reportType": "OCPP logs",
        "deviceId": "198916596-6",
        "createdDate": "08/21/2020"
    },
    {
        "createdReport": "Networked client-server array",
        "reportType": "Faults",
        "deviceId": "668765755-0",
        "createdDate": "11/19/2020"
    },
    {
        "createdReport": "Customizable discrete solution",
        "reportType": "Faults",
        "deviceId": "045652494-0",
        "createdDate": "05/19/2021"
    },
    {
        "createdReport": "Centralized radical local area network",
        "reportType": "Session Reports",
        "deviceId": "389133801-5",
        "createdDate": "09/11/2020"
    },
    {
        "createdReport": "Digitized impactful support",
        "reportType": "OCPP logs",
        "deviceId": "089569236-8",
        "createdDate": "08/12/2020"
    },
    {
        "createdReport": "Polarised maximized access",
        "reportType": "Faults",
        "deviceId": "297418986-5",
        "createdDate": "09/13/2020"
    },
    {
        "createdReport": "Business-focused logistical initiative",
        "reportType": "OCPP logs",
        "deviceId": "110939334-2",
        "createdDate": "02/07/2021"
    },
    {
        "createdReport": "Sharable context-sensitive architecture",
        "reportType": "OCPP logs",
        "deviceId": "985133088-4",
        "createdDate": "03/07/2021"
    },
    {
        "createdReport": "Business-focused stable protocol",
        "reportType": "Faults",
        "deviceId": "327977672-4",
        "createdDate": "09/06/2020"
    }
]

export const mockTemplates = [
    {
        id: 1,
        name: 'Template 1'
    },
    {
        id: 2,
        name: 'Template 2'
    },
    {
        id: 3,
        name: 'Template 3'
    }
]

export const mockRegions = [
    {
        country: 'Singapore',
        regions: [
            {
                id: 1,
                name: 'Singapore'
            }
        ]
    },
    {
        country: 'USA',
        regions: [
            {
                id: 2,
                name: 'Washington'
            },
            {
                id: 3,
                name: 'Houston'
            },
            {
                id: 4,
                name: 'L.A'
            }
        ]
    },
    {
        country: 'Cananda',
        regions: [
            {
                id: 5,
                name: 'Alberta'
            },
            {
                id: 6,
                name: 'Toronto'
            },
            {
                id: 7,
                name: 'British Columbia'
            }
        ]
    }
]

export const mockSystems = [
    {
        systemId: 'EVSE',
        devices: [
            {
                id: 1,
                name: 'EVSE1'
            },
            {
                id: 2,
                name: 'EVSE2'
            },
            {
                id: 3,
                name: 'EVSE3'
            },
            {
                id: 4,
                name: 'EVSE4'
            }
        ]
    },
    {
        systemId: 'BESS',
        devices: [
            {
                id: 5,
                name: 'BESS1'
            },
            {
                id: 6,
                name: 'BESS2'
            },
            {
                id: 7,
                name: 'BESS3'
            },
            {
                id: 8,
                name: 'BESS4'
            }
        ]
    },
    {
        systemId: 'PV',
        devices: [
            {
                id: 9,
                name: 'PV1'
            },
            {
                id: 10,
                name: 'PV2'
            },
            {
                id: 11,
                name: 'PV3'
            },
            {
                id: 12,
                name: 'PV4'
            }
        ]
    },
    {
        systemId: 'W2E',
        devices: [
            {
                id: 13,
                name: 'W2E1'
            },
            {
                id: 14,
                name: 'W2E2'
            },
            {
                id: 15,
                name: 'W2E3'
            },
            {
                id: 16,
                name: 'W2E4'
            }
        ]
    }
]

export const sites = [
    {
        id: 4,
        position: { lat: 51.528308, lng: -0.3817765 },
        name: 'London'
    },
    {
        id: 1,
        position: { lat: 43.717899, lng: -79.6582408 },
        name: 'Toronto'
    },
    {
        id: 2,
        position: { lat: 40.6971494, lng: -74.2598655 },
        name: 'New York'
    },
    {
        id: 3,
        position: { lat: 35.5090627, lng: 139.2093904 },
        name: 'Tokyo'
    }
]