import { Handler, control, terrain, scene, Renderer, wgs84, Globe, utils } from "@openglobus/og";

/** @const {string} */
const CANVAS_ID_PREFIX = "globus_viewport_";
/** @const {string} */
const PLANET_NAME_PREFIX = "globus_planet_";
export default class BlackGlobe {
    constructor(options) {
        // super(options);
        const { EarthCoordinates, MouseNavigation, TouchNavigation, Sun, ZoomControl } = control;
        const { EmptyTerrain } = terrain;
        const { Planet } = scene;
        const { isEmpty } = utils;
        // Canvas creation
        // super(options);
        var _canvasId = CANVAS_ID_PREFIX + Globe._staticCounter++;

        this._canvas = document.createElement("canvas");
        this._canvas.id = _canvasId;
        this._canvas.style.width = "100%";
        this._canvas.style.height = "100%";
        this._canvas.style.display = "block";
        this._canvas.style.opacity = "0.0";
        this._canvas.style.transition = "opacity 1.0s";

        /**
         * Dom element where WebGL canvas creates
         * @public
         * @type {Element}
         */
        this.div = document.getElementById(options.target);
        this.div.appendChild(this._canvas);
        this.div.classList.add("ogViewport");

        function _disableWheel(e) {
            e.preventDefault();
        }

        this.div.onmouseenter = function () {
            document.addEventListener("mousewheel", _disableWheel, {
                capture: false,
                passive: false
            });
        };
        this.div.onmouseleave = function () {
            document.removeEventListener("mousewheel", _disableWheel);
        };

        /**
         * Interface for the renderer context(events, input states, renderer nodes etc.)
         * @public
         * @type {og.Renderer}
         */
        this.renderer = new Renderer(
            new Handler(_canvasId, {
                context: {
                    alpha: false,
                    antialias: false,
                    powerPreference: "high-performance"
                }
            }),
            {
                autoActivate: false,
                backgroundColor: [0,0,0]
            }
        );
        this.renderer.initialize();
        this.renderer.div = this.div;
        this.renderer.div.attributions = document.createElement("div");
        if (options.attributionContainer) {
            options.attributionContainer.appendChild(this.renderer.div.attributions);
        } else {
            this.renderer.div.attributions.classList.add("ogAttribution");
            this.div.appendChild(this.renderer.div.attributions);
        }

        // Skybox
        if (options.skybox) {
            this.renderer.addNode(options.skybox);
        }

        /**
         * Planet node name. Access with this.renderer.<name>
         * @private
         * @type {String}
         */
        this._planetName = options.name ? options.name : PLANET_NAME_PREFIX + Globe._staticCounter;

        if (options.atmosphere) {
            /**
             * Render node renders a planet.
             * @public
             * @type {og.scene.Planet|og.scene.PlanetAtmosphere}
             */
            // TODO:
        } else {
            this.planet = new Planet(
                this._planetName,
                options.ellipsoid ? options.ellipsoid : wgs84,
                options.maxGridSize
            );
        }

        // Attach terrain provider
        if (options.terrain) {
            this.planet.setTerrain(options.terrain);
        } else {
            this.planet.setTerrain(new EmptyTerrain());
        }

        this.renderer.addNode(this.planet);

        // Add controls
        if (options.controls) {
            this.planet.addControls(options.controls);
        } else {
            this.planet.addControls([
                new ZoomControl(),
                new MouseNavigation(),
                new TouchNavigation(),
                new EarthCoordinates(),
                // new ScaleControl(),
                // new CompassButton()
            ]);
        }

        var _controls = this.renderer.controls;
        for (var i in _controls) {
            if (_controls[i] instanceof Sun) {
                this.sun = _controls[i];
                break;
            }
        }

        if (!this.sun) {
            this.sun = new Sun();
            this.planet.addControl(this.sun);
        }

        if (options.sun) {
            if (options.sun.active !== undefined && !options.sun.active) {
                this.sun.deactivate();
            }
        }

        if (options.layers) {
            this.planet.addLayers(options.layers);
        }

        // TODO: view center, altitude, extent
        let ve = options.viewExtent;
        if (ve) {
            if (ve instanceof Array) {
                this.planet.viewExtentArr(ve);
            } else {
                this.planet.viewExtent(ve);
            }
        }

        this._opacityCounter = 0;
        this._fadeHandler = null;
        this._stopHandler = null;

        // Run!
        if (options.autoActivate || isEmpty(options.autoActivate)) {
            this.renderer.start();
            this.fadeIn();
        }
    }
    /**
     * Starts screen brightness fading in effect by the duration time.
     * @public
     */
     fadeIn() {
        this._canvas.style.opacity = 1.0;
    }

    /**
     * Starts screen brightness fading out effect by the duration time.
     * @public
     * @param {number} duration - Fadeout duration time.
     */
    fadeOut() {
        this._canvas.style.opacity = 0.0;
    }

    static get _staticCounter() {
        if (!this._counter && this._counter !== 0) {
            this._counter = 0;
        }
        return this._counter;
    }

    static set _staticCounter(n) {
        this._counter = n;
    }

    /**
     * Returns true if the object pointer is undefined.
     * @function
     * @param {Object} obj - Object pointer.
     * @returns {boolean} Returns true if object is undefined.
     */
    static isUndefined(obj) {
        return obj === void 0;
    }
}