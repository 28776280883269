<template>
  <div class="chart">
    <div id="legend-container"></div>
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import BaseChart from "./BaseChart.vue";

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("div");

  if (!listContainer) {
    listContainer = document.createElement("div");
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.classList.add("row");
    listContainer.classList.add("justify-center");

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      chartType: "bar",
      chartOptions: {
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 20,
            // top: 20,
            bottom: 20,
          },
        },
        scales: {
          x: {
            display: true,
            stacked: true,
            grid: {
              borderColor: "transparent",
              color: "transparent",
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              maxRotation: 0,
              color: "#FCFCFC",
              font: {
                size: 14,
                family: "'Helvetica-Neue', 'Helvetica', 'Arial', sans-serif",
              },
            },
          },
          y: {
            display: false,
            grid: {
              borderColor: "#FFFFFF",
              drawBorder: true,
              color: "#FFFFFF",
              drawTicks: false,
              offset: true,
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            //   fullSize: true,
            //   padding: 10,
            //   labels: {
            //     boxWidth: 10,
            //     boxHeigth: 8,
            //     padding: 10,
            //     color: "#fff",
            //     font: {
            //       size: 15,
            //     },
            //   },
            // title: {
            //   display: true,
            //   text: "Revenue: ",
            //   position: "start",
            //   color: "#fff",

            //   font: {
            //     size: 15,
            //     family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            //   },
            // },
          },
          tooltip: {
            enabled: true,
          },
          datalabels: {
            display: function (context) {
              if (!context.dataset.displayLabel) {
                return false;
              }
              if ((context.dataIndex + 1) % 3 == 0) {
                return true;
              }
              return false;
            },
            anchor: function (context) {
              const index = context.dataIndex;
              const value = context.dataset.data[index];
              if (value > 0) {
                return "end";
              }
              return "start";
            },
            color: "#ffffff",
            align: function (context) {
              const index = context.dataIndex;
              const value = context.dataset.data[index];
              if (value > 0) {
                return "end";
              }
              return "start";
            },
            formatter: function (value, context) {
              if (context.dataset.label == "Total Revenue") {
                return Math.abs(context.dataset.trueData[context.dataIndex]);
              }
              if (value < 0 && Math.abs(value) > 10000) {
                return "";
              }
              return Math.abs(value);
            },
          },
          htmlLegend: {
            containerID: "legend-container",
          },
        },
      },
      plugins: [
        {
          id: "htmlLegend",
          afterUpdate(chart, args, options) {
            const legendContainer = getOrCreateLegendList(chart, options.containerID);

            // Remove old legend items
            while (legendContainer.firstChild) {
              legendContainer.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            const revDiv = document.createElement("div");
            revDiv.classList.add("col-md-2");
            revDiv.classList.add("col-12");
            revDiv.classList.add("chart-title-div");

            // Text
            const textContainer = document.createElement("p");
            textContainer.classList.add("chart-title-span");

            const text = document.createTextNode("Revenue:");
            textContainer.appendChild(text);
            revDiv.appendChild(textContainer);
            legendContainer.appendChild(revDiv);

            // create legend column
            const legendDiv = document.createElement("div");
            legendDiv.classList.add("col-md-8");
            legendDiv.classList.add("col-12");
            legendDiv.classList.add("chart-title-div");
            const listContainer = document.createElement("div");
            listContainer.style.margin = 0;
            listContainer.style.padding = 0;
            listContainer.classList.add("row");
            listContainer.classList.add("no-gutters");

            items.forEach((item) => {
              //2 6 4 8 4
              if (item.text) {
                const div = document.createElement("div");
                // let col = 4;
                // // if (index == 0) {
                // //   col = 6;
                // // }
                // // if (index == 2) {
                // //   col = 8;
                // // }
                div.classList.add(`col-md-6`);
                div.classList.add("col-12");
                div.classList.add("legend-div");

                div.onclick = () => {
                  const { type } = chart.config;
                  if (type === "pie" || type === "doughnut") {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                  } else {
                    chart.setDatasetVisibility(
                      item.datasetIndex,
                      !chart.isDatasetVisible(item.datasetIndex)
                    );
                  }
                  chart.update();
                };

                // Color box
                const boxSpan = document.createElement("span");
                boxSpan.style.background = item.fillStyle;
                boxSpan.classList.add("legend-box");

                // Text
                const textContainer = document.createElement("p");
                textContainer.style.textDecoration = item.hidden ? "line-through" : "";
                textContainer.classList.add("legend-text");
                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                div.appendChild(boxSpan);
                div.appendChild(textContainer);
                listContainer.appendChild(div);
              }
            });
            legendDiv.appendChild(listContainer);
            legendContainer.append(legendDiv);
          },
        },
      ],
    };
  },
};
</script>
