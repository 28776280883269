<template>
  <div class="dashboard">
    <!-- <div class="left-spacing">&nbsp;</div> -->
    <div class="left-panel">
      <div class="energy-icon">
        <div class="content">
          <div class="text">
            Energy <br />
            Generated Today
          </div>
          <div class="value">{{ energyGenerated.today }}</div>
          <div class="unit">{{ energyGenerated.today_unit }}</div>
        </div>
      </div>
      <div class="enery-generated">
        <div class="energy-monthly">
          <span class="value">{{ energyGenerated.monthly }}</span>
          <span class="unit">{{ energyGenerated.monthly_unit }}</span>
          <span class="label">Monthly Generation</span>
        </div>
        <div class="energy-total">
          <span class="value">{{ energyGenerated.total }}</span>
          <span class="unit">{{ energyGenerated.total_unit }}</span>
          <span class="label">Total Generation</span>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="today_energy_summary">
        <div class="top"><span>Today Energy Summary</span></div>
        <div class="middle">
          <div class="content">
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <div class="icon-block">
                  <button class="icon icon-today-energy-consumed"></button>
                  <div>
                    <span class="value">
                      {{ totalEnergySummary.consumed
                      }}<span class="unit">{{ totalEnergySummary.consumed_unit }}</span>
                    </span>

                    <span class="label">Consumed</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="icon-block">
                  <button class="icon icon-today-energy-generated"></button>
                  <div class="today-energy-generated">
                    <span class="value">
                      {{ totalEnergySummary.generated
                      }}<span class="unit">{{ totalEnergySummary.generated_unit }}</span>
                    </span>

                    <span class="label">Generated</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="icon-block">
                  <button class="icon icon-today-energy-net"></button>
                  <div class="today-energy-net">
                    <span class="value">
                      {{ totalEnergySummary.net_energy
                      }}<span class="unit">{{ totalEnergySummary.net_energy_unit }}</span>
                    </span>

                    <span class="label">Net Energy</span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="chart">
            <horizontal-bar-chart
              chartId="consume-chart"
              class="consumeChart"
              :chartData="chartData"
              ref="consumeChart"
            />
          </div>
        </div>
        <div class="bottom"></div>
      </div>
      <div class="clearfix"></div>
      <div class="energy-consumtion">
        <div class="top">
          <div class="title">Energy Consumption<span>kWh</span></div>
          <div class="dropdown" @click.stop>
            <!--  <select v-model="by">
                <option value="year">By Year</option>
                <option value="month" selected="">By Month</option>
              </select> -->
            <v-select
              :items="monthYearItems"
              outlined
              class="drd-month-year"
              item-text="name"
              item-value="value"
              flat
              v-model="by"
              ref="select"
            ></v-select>
            <span class="icon"></span>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="middle">
          <div class="char">
            <vertical-bar-chart
              chartId="energy-chart"
              class="energyChart"
              :chartData="energyChartData"
              ref="energyChart"
            />
          </div>
        </div>
        <div class="bottom"></div>
      </div>
    </div>
    <div class="center-panel">
      <div class="map">
        <div @click.stop>
          <v-combobox
            filled
            outlined
            shaped
            rounded
            :items="sites"
            item-text="name"
            item-value="id"
            v-model="site"
            :search-input.sync="search"
            :menu-props="menuProps"
            ref="combobox"
          ></v-combobox>
        </div>
        <div class="map-mode">
          <v-btn-toggle v-model="activeBtn">
            <v-btn
              :ripple="false"
              :disabled="!activeBtn"
              :class="!activeBtn ? 'active' : ''"
            >
              Satellite
            </v-btn>
            <v-btn
              :ripple="false"
              :disabled="!!activeBtn"
              :class="activeBtn ? 'active' : ''"
            >
              Map
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="center-top-left">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="evse-left"
              ><button class="top-left"></button
            ></v-col>
            <v-col cols="12" md="8" class="evse-right">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span class="number">{{ paddingZero(siteSummary.evse.charging) }}</span>
                  <span class="text">charging</span>
                </v-col>
              </v-row>
              <div class="yellow-divider"></div>
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span class="number text-blue">{{
                    paddingZero(siteSummary.evse.installed)
                  }}</span>
                  <span class="text text-blue">total</span>
                </v-col>
              </v-row>
              <div class="blue-divider"></div>
            </v-col>
          </v-row>
        </div>
        <div class="center-bottom-left">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="ess-left"
              ><button class="bottom-left"></button
            ></v-col>
            <v-col cols="12" md="8" class="ess-right">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span class="number">{{ paddingZero(siteSummary.ess.online) }}</span>
                  <span class="text">online</span>
                </v-col>
              </v-row>
              <div class="yellow-divider"></div>
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span class="number text-blue">{{
                    paddingZero(siteSummary.ess.installed)
                  }}</span>
                  <span class="text text-blue">total</span>
                </v-col>
              </v-row>
              <div class="blue-divider"></div>
            </v-col>
          </v-row>
        </div>
        <div class="center-top-right">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pv-left"
              ><button class="top-right"></button
            ></v-col>
            <v-col cols="12" md="8" class="pv-right">
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span class="number">{{ paddingZero(siteSummary.pv.charging) }}</span>
                  <span class="text">charging</span>
                </v-col>
              </v-row>
              <div class="yellow-divider"></div>
              <v-row no-gutters>
                <v-col cols="12" md="12">
                  <span class="number text-blue">{{
                    paddingZero(siteSummary.pv.installed)
                  }}</span>
                  <span class="text text-blue">total</span>
                </v-col>
              </v-row>
              <div class="blue-divider"></div>
            </v-col>
          </v-row>
        </div>
        <div class="center-bottom-right">
          <v-row no-gutters>
            <v-col cols="12" md="3" class="co2-left"
              ><button class="bottom-right"></button
            ></v-col>
            <v-col cols="12" md="9" class="co2-right">
              <div style="float: left">
                <span class="number">{{ paddingZero(siteSummary.co2.saving) }}</span>
                <sub class="unit">kg</sub>
              </div>
              <div style="float: left" class="co2-total">
                <span class="text"
                  ><p>Total</p>
                  <p>CO<sub>2</sub></p></span
                >
              </div>
              <div style="clear: both"></div>
              <div class="blue-divider"></div>
            </v-col>
          </v-row>
        </div>
        <globe ref="globe" :sites="sites" :searchByPoi="changeSite" />
      </div>
      <div class="tabs tab-left">
        <nav>
          <a
            class="first"
            :class="selectedFeeder == 'feeder_power_1' ? 'active' : ''"
            href="#content4"
            @click.prevent="selectedFeeder = 'feeder_power_1'"
            >FEEDER POWER 1 (MW)</a
          >
          <a
            class="mid"
            href="#content5"
            :class="selectedFeeder == 'feeder_power_2' ? 'active' : ''"
            @click.prevent="selectedFeeder = 'feeder_power_2'"
            >FEEDER POWER 2 (MW)</a
          >
          <a class="mid site-control" href="#content6" @click.prevent="1 == 1"
            >SITE CONTROL MODE</a
          >
          <a class="last" @click.prevent="1 == 1">
            <button class="btn-24" @click="realTimeBy = '24h'">24H</button>
            <button class="btn-ytd" @click="realTimeBy = 'ytd'">YTD</button>
          </a>
        </nav>
        <div class="content" id="content4">
          <div class="realTimeChart">
            <multi-axis-line-chart
              chartId="real-time-chart"
              :chartData="feederChartData"
              ref="realTimeChart"
            />
            <div class="legend-background"></div>
          </div>
        </div>
        <div class="content" style="display: none" id="content5">Content11</div>
        <div class="content" style="display: none" id="content6">Content11</div>
      </div>
    </div>
    <div class="right-panel">
      <div class="cost-rev">
        <div class="top">
          <span>Monthly Revenue VS Operation Cost (US$)</span>
        </div>
        <div class="total-rev">
          <span>${{ monthly_revenue_vs_operation_cost }}</span>
        </div>
        <div class="middle">
          <stacked-bar-chart
            chartId="chart3"
            class="costRevenueChartData"
            :chartData="costRevenueChartData"
            ref="costRevChart"
          />
        </div>
        <div class="bottom"></div>
      </div>
      <div class="demand">
        <div class="top">
          <span>Monthly Demand Charges</span>
        </div>
        <div class="middle">
          <stacked-bar-line-chart
            chartId="chart4"
            :chartData="demandChartData"
            ref="demandChart"
          />
        </div>
        <div class="bottom"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="../assets/css/dashboard.scss"></style>
<script>
import moment from "moment";
import _ from "lodash";
// import GmapCustomMarker from "vue2-gmap-custom-marker";
import { dashboardService, getTimeZone } from "../services";
import HorizontalBarChart from "../components/HorizontalBarChart.vue";
import VerticalBarChart from "../components/VerticalBarChart.vue";
import StackedBarChart from "../components/StackedBarChart.vue";
import StackedBarLineChart from "../components/StackedBarLineChart.vue";
import MultiAxisLineChart from "../components/MultiAxisLineChart.vue";
import Globe from "../components/Globe.vue";
import { monthYearItems, mapStyles } from "../contants";
import { sites } from "../mock-data";

export default {
  components: {
    HorizontalBarChart,
    VerticalBarChart,
    StackedBarChart,
    StackedBarLineChart,
    MultiAxisLineChart,
    Globe,
    // "gmap-custom-marker": GmapCustomMarker,
  },
  data() {
    return {
      sites,
      site: null,
      center: { lat: 43.717899, lng: -79.6582408 },
      siteId: 1,
      activeBtn: 1,
      by: "month",
      realTimeBy: "24h",
      costRevenue: 0,
      monthly_revenue_vs_operation_cost: 0,
      mapStyles,
      energyGenerated: {
        today: 0.0,
        today_unit: "kWh",
        monthly: 0.0,
        monthly_unit: "MWh",
        total: 0.0,
        total_unit: "MWh",
      },
      siteSummary: {
        evse: {
          installed: "00",
          charging: "00",
        },
        ess: {
          installed: "00",
          online: "00",
        },
        pv: {
          installed: "00",
          charging: "00",
        },
        co2: {
          saving: "00",
        },
      },
      totalEnergySummary: {
        consumed: 0,
        consumed_unit: "",
        generated: 0,
        generated_unit: "",
        net_energy: 0,
        net_energy_unit: "",
        ev_ch: 0,
        ev_dch: 0,
        bess_ch: 0,
        bess_dch: 0,
        pv: 0,
        w2e: 0,
        bid_loads: 0,
      },
      chartData: {
        labels: ["EV CH", "EV DCH", "BESS CH", "BESS DCH", "PV", "W2E", "Bld Loads"],
        datasets: [],
      },
      energyChartData: {
        labels: [
          "1.",
          "2.",
          "3.",
          "4.",
          "5.",
          "6.",
          "7.",
          "8.",
          "9.",
          "10.",
          "11.",
          "12.",
        ],
        datasets: [],
      },
      costRevenueChartData: {
        labels: [],
        datasets: [],
      },
      demandChartData: {
        labels: [],
        datasets: [],
      },
      feederChartData: {
        labels: [],
        datasets: [],
      },
      monthYearItems,
      summary: {
        co2: { saving: 0 },
        ess: { installed: 0, online: 0 },
        evse: { installed: 0, charging: 0 },
        pv: { installed: 0, charging: 0 },
      },
      feeders: [],
      selectedFeeder: "feeder_power_1",
      search: null,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    const els = document.getElementsByClassName("dashboard");
    if (els && els.length) {
      els[0].addEventListener("click", () => {
        this.$refs.select.blur();
        this.$refs.combobox.blur();
      });
    }
  },
  watch: {
    site(val) {
      if (val) {
        this.siteId = val.id;
        this.getData();
        const {
          position: { lng, lat },
        } = val;
        this.$refs.globe.flyTo(lng, lat);
        // this.$refs.mapRef.$mapPromise.then((map) => {
        //   map.panTo(val.position);
        // });
      }
    },
    by(val) {
      this.getEnergyConsumption(val, this.siteId);
    },
    // activeBtn(val) {
    //   const type = val ? "roadmap" : "satellite";
    //   this.$refs.mapRef.$mapPromise.then((map) => {
    //     map.setMapTypeId(type);
    //   });
    // },
    selectedFeeder(val) {
      this.setRealTimeCharts(val);
    },
    realTimeBy(val) {
      this.getRealTimeCharts(this.siteId, val);
    },
  },
  computed: {
    menuProps() {
      return !this.search ? { value: false } : {};
    },
  },
  beforeDestroy() {
    this.$refs.energyChart.$refs.chart.destroy();
    this.$refs.costRevChart.$refs.chart.destroy();
    this.$refs.demandChart.$refs.chart.destroy();
    this.$refs.realTimeChart.$refs.chart.destroy();
    this.$refs.consumeChart.$refs.chart.destroy();
  },
  methods: {
    closeSelect() {
      // const interval_check = setInterval(() => {
      //   const els = document.getElementsByClassName("v-application--wrap");
      //   if (els && els.length) {
      //     clearInterval(interval_check);
      //     els[0].addEventListener("click", () => {
      //       this.$refs.select.blur();
      //     });
      //   }
      // }, 1000);
    },
    changeSite(site_id) {
      if (site_id) {
        const site = this.sites.find((site) => site.id == site_id);
        this.site = Object.assign({}, this.site, site);
      }
    },
    getData() {
      this.getTotalEnergerySummary(this.siteId);
      this.getEnergyConsumption(this.by, this.siteId);
      this.getRevenueAndCost(this.siteId);
      this.getMonthlyDemandCharges(this.siteId);
      this.getRealTimeCharts(this.siteId, this.realTimeBy);
      this.getSiteSummary(this.siteId);
      this.getEnergyGenerated(this.siteId);
    },
    getEnergyGenerated(site_id) {
      dashboardService.getEnergyGenerated(site_id).then((res) => {
        if (res?.data?.data?.data) {
          this.energyGenerated = Object.assign(
            {},
            this.energyGenerated,
            res.data.data.data
          );
        }
      });
    },
    getTotalEnergerySummary(siteId) {
      dashboardService.getTodayEnergySummary(siteId).then((res) => {
        if (res?.data?.data?.data) {
          this.setTotalEnergySummary(res.data.data.data);
        }
      });
    },
    setTotalEnergySummary(data) {
      const chartDataSetData = [];
      const mockChartDataSets1 = [];
      const mockChartDataSets2 = [];
      const {
        ev_ch,
        ev_dch,
        bess_ch,
        bess_dch,
        pv,
        w2e,
        bid_loads,
        consumed,
        consumed_unit,
        generated_unit,
        generated,
        net_energy,
        net_energy_unit,
      } = data;
      this.totalEnergySummary = Object.assign({}, this.totalEnergySummary, {
        consumed,
        consumed_unit,
        generated_unit,
        generated,
        net_energy,
        net_energy_unit,
      });
      const min = _.min([ev_ch, ev_dch, bess_ch, bess_dch, pv, w2e, bid_loads]);
      const max = _.max([ev_ch, ev_dch, bess_ch, bess_dch, pv, w2e, bid_loads]);
      chartDataSetData.push(ev_ch);
      chartDataSetData.push(ev_dch);
      chartDataSetData.push(bess_ch);
      chartDataSetData.push(bess_dch);
      chartDataSetData.push(pv);
      chartDataSetData.push(w2e);
      chartDataSetData.push(bid_loads);

      mockChartDataSets1.push(max + 30);
      mockChartDataSets1.push(max + 30);
      mockChartDataSets1.push(max + 30);
      mockChartDataSets1.push(max + 30);
      mockChartDataSets1.push(max + 30);
      mockChartDataSets1.push(max + 30);
      mockChartDataSets1.push(max + 30);

      mockChartDataSets2.push(min - 30);
      mockChartDataSets2.push(min - 30);
      mockChartDataSets2.push(min - 30);
      mockChartDataSets2.push(min - 30);
      mockChartDataSets2.push(min - 30);
      mockChartDataSets2.push(min - 30);
      mockChartDataSets2.push(min - 30);
      const chartData = Object.assign({}, this.chartData, {
        datasets: [
          {
            data: [...chartDataSetData],
            backgroundColor: "#08cdd6",
            name: "real",
            barThickness: 16,
            borderWidth: 1,
            borderColor: "#2688C4",
          },
          {
            data: [...mockChartDataSets1],
            backgroundColor: "transparent",
            barThickness: 16,
            borderWidth: 1,
            borderColor: "#2688C4",
            name: "mock",
          },
          {
            data: [...mockChartDataSets2],
            backgroundColor: "transparent",
            barThickness: 16,
            borderWidth: 1,
            borderColor: "#2688C4",
            name: "mock",
          },
        ],
      });
      this.$refs.consumeChart.$refs.chart.updateChart(chartData);
    },
    getEnergyConsumption(by, siteId) {
      dashboardService.getEnergyConsumption(by, siteId).then((res) => {
        if (res?.data?.data?.data) {
          this.setEnergyConsumption(res.data.data.data);
        }
      });
    },
    setEnergyConsumption(data) {
      const years = Object.keys(data);
      if (years?.length) {
        years.sort((a, b) => a - b);
        if (this.by == "month") {
          this.setConsumptionChartByMonth(years, data);
        }
        if (this.by == "year") {
          this.setConsuptionChartByYear(years, data);
        }
      }
    },
    setConsumptionChartByMonth(years, data) {
      const datasets = [];

      var energy_chart = document.getElementById("energy-chart");
      var ctx = energy_chart.getContext("2d");

      var gradientYear1 = ctx.createLinearGradient(0, 0, 0, 220);
      gradientYear1.addColorStop(0, "#016469");
      gradientYear1.addColorStop(1, "#08CDD6");

      var gradientYear2 = ctx.createLinearGradient(0, 0, 0, 220);
      gradientYear2.addColorStop(0, "#CBFFFC");
      gradientYear2.addColorStop(1, "#0C7FB1");

      const lastYear = {
        label: `Year ${years[0]}`,
        data: [],
        // backgroundColor: "#048d93",
        backgroundColor: gradientYear2,
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
      };
      const currentYear = {
        label: `Year ${years[1]}`,
        data: [],
        // backgroundColor: "#8ad8ec",
        backgroundColor: gradientYear1,
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
      };
      for (let i = 1; i <= 12; i++) {
        currentYear.data.push(data[years[1]][i] || 0);
        lastYear.data.push(data[years[0]][i] || 0);
      }
      datasets.push(currentYear);
      datasets.push(lastYear);

      const chartData = Object.assign({}, this.energyChartData, {
        datasets: [...datasets],
      });
      this.$refs.energyChart.$refs.chart.updateChart(chartData);
    },
    setConsuptionChartByYear(years, data) {
      var energy_chart = document.getElementById("energy-chart");
      var ctx = energy_chart.getContext("2d");

      var gradientYear1 = ctx.createLinearGradient(0, 0, 0, 400);
      gradientYear1.addColorStop(0, "#016469");
      gradientYear1.addColorStop(1, "#08CDD6");
      const energyChartData = {
        labels: [],
        datasets: [],
        hideLegend: true,
      };
      const yearData = {
        label: "Years",
        data: [],
        // backgroundColor: "#8ad8ec",
        backgroundColor: gradientYear1,
        borderRadius: 50,
        borderSkipped: false,
        barThickness: 9,
        borderWidth: 1,
      };
      years.map((year) => {
        energyChartData.labels.push(year);
        yearData.data.push(data[year]);
      });
      energyChartData.datasets.push(yearData);
      this.$refs.energyChart.$refs.chart.updateChart(energyChartData);
    },
    getRevenueAndCost(site_id) {
      dashboardService.getRevenueAndCost(site_id).then((res) => {
        if (res?.data?.data?.data?.data) {
          this.setRevenueAndCost(res.data.data.data.data);
          this.monthly_revenue_vs_operation_cost = res.data.data.data.monthly_revenue_vs_operation_cost.toLocaleString(
            "us"
          );
        }
      });
    },
    setRevenueAndCost(data) {
      this.costRevenue = data.monthly_revenue_vs_operation_cost || 0;
      // delete data.monthly_revenue_vs_operation_cost;
      const months = Object.keys(data);

      // var chart4 = document.getElementById("chart4");
      // var ctx = chart4.getContext("2d");

      // var gradientOffPeak = ctx.createLinearGradient(0, 0, 0, 150);
      // gradientOffPeak.addColorStop(0, "#016469");
      // gradientOffPeak.addColorStop(1, "#3FB4BA");

      if (months?.length) {
        let chartData = {
          labels: [],
          datasets: [],
        };
        const offPeakDatasets = {
          label: "Off-Peak",
          backgroundColor: "#F2F781",
          data: [],
        };
        const midPeakDatasets = {
          label: "Mid-Peak",
          backgroundColor: "#8BC33E",
          data: [],
        };
        const onPeakDatasets = {
          label: "On-Peak",
          backgroundColor: "#4C9D21",
          data: [],
        };
        const operationDatasets = {
          label: "Operation Cost",
          backgroundColor: "#49C1B8",
          data: [],
          displayLabel: true,
        };
        const totalRevenue = {
          label: "",
          backgroundColor: "transparent",
          data: [],
          displayLabel: true,
          trueData: [],
        };
        months.map((val) => {
          chartData.labels.push(moment(val).format("DD"));
          const {
            off_peak_revenue,
            mid_peak_revenue,
            on_peak_revenue,
            operation_cost,
            total_revenue,
          } = data[val];
          onPeakDatasets.data.push(on_peak_revenue || 0);
          midPeakDatasets.data.push(mid_peak_revenue || 0);
          offPeakDatasets.data.push(off_peak_revenue || 0);

          operationDatasets.data.push(0 - operation_cost || 0);
          const max = _.max([off_peak_revenue, mid_peak_revenue, on_peak_revenue]);
          // const min = _.min([off_peak_revenue, mid_peak_revenue, on_peak_revenue]);
          totalRevenue.data.push((max || 0) + 2000);
          totalRevenue.trueData.push(total_revenue || 0);
        });

        chartData.datasets.push(onPeakDatasets);
        chartData.datasets.push(midPeakDatasets);
        chartData.datasets.push(offPeakDatasets);

        chartData.datasets.push(operationDatasets);
        chartData.datasets.push(totalRevenue);
        this.$refs.costRevChart.$refs.chart.updateChart(chartData);
      }
    },
    getMonthlyDemandCharges(site_id) {
      dashboardService.getMonthlyDemandCharges(site_id).then((res) => {
        if (res?.data?.data) {
          this.setMonthlyDemandCharges(res.data.data);
        }
      });
    },
    setMonthlyDemandCharges(data) {
      const chartData = {
        labels: [],
        datasets: [],
      };

      var chart4 = document.getElementById("chart4");
      var ctx = chart4.getContext("2d");

      var gradient = ctx.createLinearGradient(0, 0, 0, 150);
      gradient.addColorStop(0, "#016469");
      gradient.addColorStop(1, "#3FB4BA");

      const demandDataSets = {
        label: "Demand (kW)",
        // backgroundColor: "#127a80",
        backgroundColor: gradient,
        borderRadius: 50,
        barThickness: 8,
        borderWidth: 1,
        data: [],
        stack: "combined",
        type: "bar",
        order: 2,
      };
      const maxDatasets = {
        label: "Max Demand (kW)",
        backgroundColor: "#E0F2BF",
        borderColor: "#E0F2BF",
        data: [],
        stack: "combined",
        order: 1,
        pointRadius: 0,
      };
      const demandData = data.data.demand.data;
      const maxData = data.data.max_demand.data;
      let months = [];
      if (Object.keys(demandData).length > Object.keys(maxData).length) {
        months = [...Object.keys(demandData)];
      } else {
        months = [...Object.keys(maxData)];
      }
      months.map((val) => {
        chartData.labels.push(val);
        demandDataSets.data.push(demandData[val] || 0);
        maxDatasets.data.push(maxData[val] || 0);
      });
      chartData.datasets.push(demandDataSets);
      chartData.datasets.push(maxDatasets);
      this.$refs.demandChart.$refs.chart.updateChart(chartData);
    },
    getRealTimeCharts(site_id, by) {
      const timezone = getTimeZone();
      dashboardService.getRealTimeCharts(site_id, by, timezone).then((res) => {
        if (res?.data?.data?.data?.tabs_data) {
          this.feeders = Object.assign({}, this.feeders, res.data.data.data.tabs_data);
          this.setRealTimeCharts(this.selectedFeeder);
        }
      });
    },
    setRealTimeCharts(feederName) {
      const feeder = this.feeders[feederName];
      const labels = Object.keys(feeder.bess.data);
      // const today = moment().format("YYYY-MM-DD");
      labels.sort((a, b) => {
        const timeA = moment(`${a}`);
        const timeB = moment(`${b}`);
        if (timeA.isAfter(timeB, "hour")) {
          return 1;
        }
        if (timeA.isBefore(timeB, "hour")) {
          return -1;
        }
        return 0;
      });
      if (this.realTimeBy == "ytd") {
        // labels.push(labels[0]);
        labels.push("24:00");
      } else {
        labels.push(labels[labels.length - 1]);
      }
      const chartData = {
        labels,
        datasets: [],
      };
      const bessDatasets = {
        label: "BESS",
        borderColor: "#788cff",
        backgroundColor: "#788cff",
        yAxisID: "y1",
        data: [],
      };
      const evseDatasets = {
        label: "EVSE Charge",
        borderColor: "#8dff8d",
        backgroundColor: "#8dff8d",
        yAxisID: "y1",
        data: [],
      };
      const feedLoadDatasets = {
        label: "Feeder Load Power",
        borderColor: "#ff83ff",
        backgroundColor: "#ff83ff",
        yAxisID: "y1",
        data: [],
      };
      const pvDatasets = {
        label: "PV",
        borderColor: "#f9f382",
        backgroundColor: "#f9f382",
        yAxisID: "y1",
        data: [],
      };
      const wasteDatasets = {
        label: "Waste to Energy",
        borderColor: "#fedeff",
        backgroundColor: "#fedeff",
        yAxisID: "y1",
        data: [],
      };
      const infraLimit = {
        label: "Infrastructure Limit",
        hideLegend: true,
        borderColor: "#ff5a85",
        backgroundColor: "#ff5a85",
        yAxisID: "y1",
        data: [],
      };
      const operatingLimit = {
        label: "Operating Limit",
        hideLegend: true,
        borderColor: "#29d2d6",
        backgroundColor: "#29d2d6",
        yAxisID: "y1",
        data: [],
      };
      let infra = 0;
      let operating = 0;
      const {
        bess,
        evse_charge,
        feeder_load_power,
        pv,
        waste_to_energy,
        infrastructure_limit,
        operating_limit,
      } = feeder;
      labels.map((val, index) => {
        let pushIndex = val;
        if (index == labels.length - 1) {
          pushIndex = labels[index - 1];
          infra = infrastructure_limit.data[pushIndex] || 0;
          operating = operating_limit.data[pushIndex] || 0;
        }
        bessDatasets.data.push(bess.data[pushIndex] || 0);
        evseDatasets.data.push(evse_charge.data[pushIndex] || 0);
        feedLoadDatasets.data.push(feeder_load_power.data[pushIndex] || 0);
        pvDatasets.data.push(pv.data[pushIndex] || 0);
        wasteDatasets.data.push(waste_to_energy.data[pushIndex] || 0);
        infraLimit.data.push(infrastructure_limit.data[pushIndex] || 0);
        operatingLimit.data.push(operating_limit.data[pushIndex] || 0);
      });
      chartData.datasets.push(bessDatasets);
      chartData.datasets.push(evseDatasets);
      chartData.datasets.push(feedLoadDatasets);
      chartData.datasets.push(pvDatasets);
      chartData.datasets.push(wasteDatasets);
      chartData.datasets.push(infraLimit);
      chartData.datasets.push(operatingLimit);
      this.$refs.realTimeChart.$refs.chart.chart.options.plugins.horizontalLine = [
        {
          y: operating,
          style: "transparent",
          text: "Operating Limit",
        },
        {
          y: infra,
          style: "transparent",
          text: "Infrastructure Limit",
        },
      ];
      this.$refs.realTimeChart.$refs.chart.updateChart(chartData);
    },
    getSiteSummary(site_id) {
      dashboardService.getSiteSummary(site_id).then((res) => {
        if (res?.data?.data?.data) {
          this.setSiteSummary(res.data.data.data);
        }
      });
    },
    setSiteSummary(data) {
      this.siteSummary = Object.assign({}, this.siteSummary, data);
    },
    paddingZero(num) {
      if (num < 10) {
        return String(num).padStart(2, "0");
      }
      return num;
    },
  },
};
</script>
