<template>
  <div class="chart">
    <chart
      ref="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :chartId="chartId"
      :plugins="plugins"
    />
  </div>
</template>
<script>
import BaseChart from "./BaseChart.vue";
import moment from "moment";
export default {
  components: {
    chart: BaseChart,
  },
  props: {
    chartId: String,
    chartData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      chartType: "line",
      chartOptions: {
        responsive: true,
        pointRadius: 0,
        interaction: {
          mode: "index",
          intersect: false,
        },
        stacked: false,
        scales: {
          x: {
            display: true,
            grid: {
              borderColor: "#112138",
              color: "#112138",
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              callback: function (value) {
                if (value % 2 == 0) {
                  if (this.getLabelForValue(value) == "24:00") {
                    return this.getLabelForValue(value);
                  }
                  // const utc = moment.utc(this.getLabelForValue(value)).toDate();
                  // return moment(utc).local().format("HH:mm");
                  return moment(this.getLabelForValue(value)).format("HH:mm");
                }
                return "";
              },
              maxRotation: 0,
              color: "#fff",
              // crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 10,
              },
            },
          },
          y1: {
            display: true,
            type: "linear",
            ticks: {
              color: "#fff",
              crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 13,
              },
              padding: 10,
              stepSize: 10,
            },
            grid: {
              borderColor: "#112138",
              drawBorder: false,
              // color: function (context) {
              //   if (context.tick.value % 10 == 0) {
              //     return "#112138";
              //   }

              //   return "transparent";
              // },
              color: "#112138",
              drawTicks: false,
            },
            position: "left",
            suggestedMax: 60,
          },
          y2: {
            display: false,
            type: "linear",
            ticks: {
              color: "#fff",
              crossAlign: "far",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 13,
              },
              // padding: 10
            },
            grid: {
              borderColor: "#112138",
              drawBorder: false,
              drawTicks: false,
              drawOnChartArea: false,
              color: function (context) {
                if (context.tick.value % 10 == 0) {
                  return "#112138";
                }

                return "transparent";
              },
            },
            position: "right",
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            color: "#fff",
            labels: {
              padding: 30,
              boxWidth: 9,
              boxHeigth: 6,
              color: "#fff",
              font: {
                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                size: 11,
              },
              filter: (legendItem) => {
                // First, retrieve the data corresponding to that label
                // const label = legendItem.text
                // const labelIndex = _.findIndex(data.labels, (labelName) => labelName === label) // I'm using lodash here
                // const qtd = data.datasets[0].data[labelIndex]

                // // Second, mutate the legendItem to include the new text
                // legendItem.text = 'abc'

                if (legendItem.datasetIndex == 5 || legendItem.datasetIndex == 6) {
                  return false;
                  // legendItem.text = "";
                  // legendItem.lineWidth = 0;
                  // legendItem.fillStyle = '#0f2432';
                }

                // Third, the filter method expects a bool, so return true to show the modified legendItem in the legend
                return true;
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          datalabels: {
            display: false,
          },
          horizontalLine: [],
        },
      },
      plugins: [
        {
          id: "horizontalLine",
          afterDraw: (chart, args, options) => {
            var ctx = chart.ctx;
            args.a = "";
            ctx.save();
            // var canvas = chart.canvas;
            const yScale = chart.scales["y1"];
            const xScale = chart.scales["x"];
            const xValue = xScale.getPixelForValue(0);
            let maxValue = 0;
            var index;
            var line;
            var style;
            var yValue;
            if (options.length) {
              if (chart?.data?.datasets[0]?.data?.length) {
                maxValue = xScale.getPixelForValue(
                  chart.data.datasets[0].data.length - 1
                );
              }
              for (index = 0; index < options.length; index++) {
                line = options[index];

                if (!line.style) {
                  style = "rgba(169,169,169, .6)";
                } else {
                  style = line.style;
                }

                if (line.y) {
                  yValue = yScale.getPixelForValue(line.y);
                } else {
                  yValue = 0;
                }

                if (yValue) {
                  ctx.lineWidth = 2;
                  ctx.beginPath();
                  ctx.moveTo(xValue, yValue);
                  ctx.lineTo(maxValue, yValue);
                  ctx.strokeStyle = style;
                  ctx.stroke();
                }

                if (line.text) {
                  ctx.lineWidth = 7;
                  ctx.fillStyle = "#fff";
                  ctx.textAlign = "right";
                  ctx.font = "9px Helvetica";
                  ctx.fillText(line.text, maxValue, yValue - ctx.lineWidth);
                }
              }
              ctx.restore();
              return;
            }
          },
        },
      ],
    };
  },
};
</script>

<style></style>
